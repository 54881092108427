<template>
  <div v-loading="loading" style="min-height:300px">
    <el-row :gutter="10">
    </el-row>
  </div>
</template>

<script>
  export default{
    data(){
      return{
        loading: false,
        data:[
        ],
      }
    },
    created(){
    },
    mounted () {
    },
    computed:{
      user(){
        return this.$user();
      }
    },
    methods: {
    }
  }
</script>
<style>
</style>