<template>
    <div>   
     <el-row :gutter="5" style="margin-bottom: 10px;">
        <el-col :span="4">
            <SelectSport v-model="paginationFilter.sport_id"></SelectSport>
        </el-col>
        <el-col :span="4">
            <el-select v-model="paginationFilter.type" style="width:100%" placeholder="Tür Seçiniz"> 
              <el-option :key="0" label="Prematch" :value="0"> </el-option>
              <el-option :key="1" label="Canlı" :value="1"> </el-option>
            </el-select>
      </el-col>
     </el-row>
      <el-table
        v-loading="loading"
        :fixed="false"
        :data="markets"
        style="width: 100%">
        <el-table-column
          label="Market ID"
          width="100">
          <template slot-scope="scope">
            #{{ scope.row.id }}
          </template>
        </el-table-column>
        <el-table-column label="Diller">
            <el-table-column
            :key="langKey"
            v-for="item,langKey in langs"
            :label="item.code">
            <template slot-scope="scope">
                <el-input v-if="scope.row.translations && scope.row.translations[item.code] && scope.row.translations[item.code].value" @blur="changeEvent(scope.row.id, item.id, $event, item.code);" v-model="scope.row.translations[item.code].value"> </el-input>
                <div class="el-input" v-else>
                  <input class="el-input__inner" type="text" @blur="changeEvent(scope.row.id, item.id, $event, item.code);">  
                </div>
            </template>
            </el-table-column>
        </el-table-column>
        <el-table-column
          label="Outcomes">
          <template slot-scope="scope">
            <el-button @click="modal = scope.row.id; $forceUpdate()" type="primary">Oran Çevirileri</el-button>
            <el-dialog
                title="Oran Çevirileri"
                :visible.sync="modal === scope.row.id"
                width="90%"
                :show-close="false">
                <el-button  style="position: absolute; top: 14px; right: 20px; border-radius: 30px;"  type="primary" @click="modal = false">Kapat</el-button>
                <Outcomes :marketType="paginationFilter.type" :langs="langs" :marketId="scope.row.id" :sportId="paginationFilter.sport_id"/>
            </el-dialog>
          </template>
        </el-table-column>
      </el-table>
      <div class="block pagination">
        <el-pagination
            v-if="paginationFilter.pageTotal > 0"
            @size-change="handleSizeChange"
            @current-change="fetch"
            :current-page.sync="paginationFilter.page"
            :page-size="paginationFilter.size"
            layout="sizes, prev, pager, next"
            :total="paginationFilter.pageTotal"
        ></el-pagination>
      </div> 
    </div>
</template>
<script>
  import Outcomes from './outcomes'
  export default{
    props:['langs'],
    data() {
      return {
        markets:[],
        loading:false,
        modal: false,
        paginationFilter:{
          sport_id: 250,
          pageTotal: 0,
          type:0,
          size: this.$route.query.size ? parseInt(this.$route.query.size) : 20,
          sort: null,
          page: this.$route.query.page ? this.$route.query.page : 1,
        },
      };
    },
    components:{
        Outcomes,
    },
    watch:{
        'paginationFilter.sport_id'(){
            this.fetch();
        },
        'paginationFilter.type'(){
            this.fetch();
        }
    },
    created(){
      this.fetch();
      this.fetchSports()
    },
    methods: {
        changeEvent(id, lang_id, event, code){
          if(!_.isEmpty(event.target.value)){
            
            axios.post('translations/' + (this.paginationFilter.type == 0 ? 'market' : 'translatemarketlive') + '/' + id, { value: event.target.value, language_id: lang_id})
            .then((response) => {
                if(response.data.status){
                    this.$store.dispatch('alert/message', { type: 'success', title: event.target.value + ' olarak değiştirildi.' });

                }else{
                    this.$store.dispatch('alert/message', { type: 'error', title: response.data.message });
                }
            })
            .catch((err) => {
            })
            .finally(()=>{
                this.loading = false;
            })
          }else{
            return false
          }
        },
        fetchSports(){
            this.loading = true;
            axios.get('/sports', { params: { ...this.paginationFilter } })
            .then((response) => {
                this.sports = response.data.data;
                this.paginationFilter.pageTotal  	= response.data.meta.total;
            })
            .catch((err) => {
            })
            .finally(()=>{
            this.loading = false;
            });
        },
        handleSizeChange(value){
            this.paginationFilter.size = value;
            this.fetch();
        },
        fetch(){
            this.loading = true;
            axios.get('/markets', { params: { ...this.paginationFilter } })
            .then((response) => {
                this.markets = response.data.data;
                this.paginationFilter.pageTotal  	= response.data.meta.total;
            })
            .catch((err) => {
            })
            .finally(()=>{
            this.loading = false;
            });
        },
    }
  };
</script>