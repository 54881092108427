<template>
  <el-select
    v-model="content"
    collapse-tags
    @input="handleInput"
    style="width:100%"
    placeholder="Select Currency">
    <el-option
      v-for="item in data"
      :key="item.id"
      :label="item.code"
      :value="item.id">
    </el-option>
  </el-select>
</template>
<script>
  export default {
    props:['value','data'],
    data() {
      return {
        content:this.value,
      }
    },
    methods:{
      handleInput (e) {
        this.$emit('input', this.content)
      },
    },
    watch:{
      value(val){
        this.content = val
      }
    },
  }
</script>