<template>
  <el-pagination
      v-if="filter.pageTotal > 0"
      @size-change="handleSizeChange"
      @current-change="fetch"
      :current-page.sync="filter.page"
      :page-size="filter.size"
      layout="sizes, prev, pager, next"
      :total="filter.pageTotal"
  ></el-pagination>
</template>

<script>
  export default{
    props:['filter'],
  }
</script>
