<template>
  <div class="mobile-app-panel left">
      <Menu/>
  </div>
</template>

<script>
  import Menu from './Menu'
  export default{
    data(){
      return{
      }
    },
    components:{
      Menu
    },
  }
</script>