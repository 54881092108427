<template>
  <div>
    <el-card>
      <div slot="header" class="clearfix">
        <span>Hesabım</span>
      </div>
        <el-form size="small" label-position="top" v-if="!_.isEmpty(form)">
          <el-row :gutter="10">
            <el-col :span="6">
              <el-form-item label="E-Mail">
                <el-input disabled required placeholder="E-Mail" type="email"  v-model="form.email"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="Şifre">
                <el-input type="password" required placeholder="Şifre" v-model="form.password"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item style="margin-top:27px">
                <el-button v-ripple  :loading="loading" @click="edit()" type="primary">Güncelle</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
    </el-card>
  </div>
</template>

<script>
  export default{
    name:'Edit',
    data(){
      return{
          form:{
          },
          tabPosition: "1",
          loading: false,
      }
    },
    created(){
      this.fetch();
    },
    components:{
    },
    computed:{
      user(){
        return this.$user();
      }
    },
    methods:{
      fetch(){
        this.loading = true;
        axios.get('/auth/user')
        .then((response) => {
            this.form = response.data.data;
            this.loading = false;
        })
        .catch((err) => {
        });
      },
      edit(){
        this.loading = true;
        axios.patch('/admin/account', this.form)
        .then((response) => {
            if(response.status){
              this.$store.dispatch('alert/message', { type: 'success', title: 'Success !'});
              this.fetch();
            }else{
              this.$store.dispatch('alert/message', { type: 'error', title: response.data.message });
            }
        })
        .catch((err) => {
        });
      }
    }
  }
</script>