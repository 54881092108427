<template>
  <div>
   <el-row :gutter="5">
     <el-col :span="21">
        <el-alert style="height:37px" :closable="false" title="Görünümünüzü özelleştirin, Web ve Mobil aynıdır.">
        </el-alert>
     </el-col>
     <el-col :span="3">
      <el-button :disabled="loading" style="width:100%" @click="setPatch" type="primary"><i class="el-icon-check"></i> Yayınla</el-button>
     </el-col>
     <br><br><br>
     <el-col :span="24">
      <el-input
        v-if="!loading"
        style="width:100%"
        type="textarea"
        :rows="30"
        v-model="template">
      </el-input>
     </el-col>
   </el-row>
  </div>
</template>

<script>
  export default{
      data(){
        return{
          loading:false,
          template:{
          }
        }
      },
      created(){
        this.fetch();
      },
      computed:{
        client_id(){
          return this.$user_client_id()
        }
      },
      methods:{
        setPatch(){
          this.loading = true;
          axios.patch('/clients/' + this.client_id + '/template', { template: this.template })
          .then((response) => {
              if(response.status){
                this.$store.dispatch('alert/message', { type: 'success', title: 'Yayına Alındı!'});
                this.fetch();
              }else{
                this.$store.dispatch('alert/message', { type: 'error', title: response.data.message });
              }
          })
          .catch((err) => {
          })
          .finally(()=>{
              this.loading = false;
          })
        },
        fetch(){
          this.loading = true;
          axios.get('/clients/' + this.client_id + '/template')
          .then((response) => {
            this.template = response.data.data;
          })
          .catch((err) => {
          })
          .finally(()=>{
              this.loading = false;
          })
        },
      }
  }
</script>
