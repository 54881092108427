<template>
  <div class="login-form">
    <div class="login" style="padding:40px">
      <el-form label-position="top">
        <el-form-item label="E-Mail Adress">
          <el-input placeholder="Write your e-mail adress" v-model="form.email"></el-input>
        </el-form-item>
        <el-form-item @keyup.enter.native="handleLogin" label="Password">
          <el-input placeholder="Write your password" type="password" v-model="form.password"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button v-ripple  :disabled="isLoading" @click="handleLogin" style="width:100%;" type="primary">
            <i class="el-icon-d-arrow-right"></i> Login
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from "vuex";
  export default{
    name: 'Login',
    data(){
      return{
        form:{
          email:null,
          password:null,
        }
      }
    },
    computed:{
      ...mapState("auth", ["isLoading"])
    },
    methods:{
      ...mapActions("auth", ["login"]),
      handleLogin() {
        this.login(this.form)
      }
    },
  }
</script>