<template>
    <div class="el-panel-menu">
      <router-link v-if="!isMobile" class="logo-el-menu" to="/" @click.native="triggerPanel('')">
        <img src="@/assets/images/logo.png" width="100">
      </router-link>
      <el-menu router mode="vertical" :default-active="$route.path" v-if="user">
          <template v-for="(page, index) in $router.options.routes">
                <slot v-if="!page.property.is_owner || user.is_owner">
                  <el-submenu v-if="page.children && page.property.visible && page.children.length > 0" :key="index" :index="page.path">
                      <template slot="title"><span v-html="page.property.icon"></span> {{ page.property.title }}</template>
                        <slot  v-for="(child, index) in page.children">
                          <slot v-if="!child.property.is_owner || user.is_owner">
                            <slot v-if="!child.property.is_reseller || user.is_reseller">
                                <el-menu-item  v-ripple v-if="child.property.visible"  :key="index" :index="page.path + '/' + child.path">
                                  {{ child.property.title }}
                                </el-menu-item>
                            </slot>
                          </slot>
                        </slot>
                  </el-submenu>
                  <el-menu-item  v-ripple v-if="_.isUndefined(page.children) && page.property.visible" :key="index" :index="page.path">
                    <span v-html="page.property.icon"></span>  {{ page.property.title }}
                  </el-menu-item>
                </slot>
          </template>
          <li role="menuitem" @click="logout" tabindex="-1" class="el-menu-item" style="padding-left: 20px;">
            <i class="el-icon-d-arrow-left"></i> 
            Log out
          </li>
      </el-menu>
    </div>
</template>
<script>

  export default{
    methods:{
      logout(){
          this.$store.dispatch('auth/logout');
      },
    },
    computed:{
      user(){
        return this.$user();
      }
    },
  }
  
</script>