<template>
  <div>
      <FilterForm v-loading="loading">
        <template slot="content">
          <el-form :inline="true">
            <el-form-item :style="!isMobile ? 'width: 150px !important;' : ''">
              <el-input clearable size="medium" v-model="filter.id" placeholder="ID İle Ara"></el-input>
            </el-form-item>
            <el-form-item :style="!isMobile ? 'width: 150px !important;' : ''">
              <el-select size="medium" v-model="filter.bet_type" style="width:100%" placeholder="Tür Seçiniz"> 
                <el-option :key="0" label="Prematch" :value="0"> </el-option>
                <el-option :key="1" label="Canlı" :value="1"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item :style="!isMobile ? 'width: 150px !important;' : ''">
              <el-input clearable size="medium" v-model="filter.sport_id" placeholder="Spor ID İle Ara"></el-input>
            </el-form-item>
            <el-form-item :style="!isMobile ? 'width: 150px !important;' : ''">
              <el-input clearable size="medium" v-model="filter.category_id" placeholder="Kategori ID İle Ara"></el-input>
            </el-form-item>
            <el-form-item :style="!isMobile ? 'width: 150px !important;' : ''">
              <el-input clearable size="medium" v-model="filter.tournament_id" placeholder="Turnuva ID İle Ara"></el-input>
            </el-form-item>
            <el-form-item :style="!isMobile ? 'width: 150px !important;' : ''">
              <el-input clearable size="medium" v-model="filter.match_id" placeholder="Maç ID İle Ara"></el-input>
            </el-form-item>
            <el-form-item :style="!isMobile ? 'width: 100px !important;' : ''">
              <el-button v-ripple  size="medium" type="primary" @click="filterBtn">Ara</el-button>
            </el-form-item>
            <el-form-item :style="!isMobile ? 'width: 100px !important;' : ''">
              <el-button v-ripple  size="medium" type="info" @click="clearBtn">Temizle</el-button>
            </el-form-item>
          </el-form>
        </template>
      </FilterForm>
      <div class="block pagination">
        <el-pagination
            v-if="paginationFilter.pageTotal > 0"
            @size-change="handleSizeChange"
            @current-change="fetch"
            :current-page.sync="paginationFilter.page"
            :page-size="paginationFilter.size"
            layout="sizes, prev, pager, next"
            :total="paginationFilter.pageTotal"
        ></el-pagination>
      </div> 
    <el-dialog
      :title="isCreate ? 'Yeni Limit Oluştur' : 'Düzenle'"
      :visible.sync="modal"
      width="30%">
      <el-form label-position="top" v-if="!_.isEmpty(form)">
        <el-row :gutter="10">
        <slot v-if="isCreate">
          <el-col :span="24">
            <el-form-item label="Limit Türü">
              <el-radio v-model="form.type" :label="0">Spor</el-radio> 
              <el-radio v-model="form.type" :label="1">Kategori</el-radio>
              <el-radio v-model="form.type" :label="2">Turnuva</el-radio>
              <el-radio v-model="form.type" :label="3">Karşılaşma</el-radio>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Spor">
              <SelectSport v-model="form.sport_id"></SelectSport>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="(form.sport_id) && (form.type == 1 || form.type == 2 || form.type == 3)">
            <el-form-item label="Kategori">
              <SelectCategory :sport_id="form.sport_id" v-model="form.category_id"></SelectCategory>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="(form.sport_id && form.category_id) && (form.type == 2 || form.type == 3)">
            <el-form-item label="Turnuva">
              <SelectTournament :category_id="form.category_id" :sport_id="form.sport_id" v-model="form.tournament_id"></SelectTournament>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="(form.sport_id && form.category_id && form.tournament_id) && (form.type == 3)">
            <el-form-item label="Maç">
              <SelectMatch :tournament_id="form.tournament_id" :category_id="form.category_id" :sport_id="form.sport_id" v-model="form.match_id"></SelectMatch>
            </el-form-item>
          </el-col>
        </slot> 
        <el-col :span="24">
          <el-form-item label="Tutar">
            <el-input  autocomplete="off" required placeholder="Tutar" disabled value="10000"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="Yüzde">
            <el-input  autocomplete="off" required placeholder="Yüzde" v-model="form.percent"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="Tür">
            <el-radio v-model="form.bet_type" :label="0">Prematch</el-radio> 
            <el-radio v-model="form.bet_type" :label="1">Live</el-radio>
          </el-form-item>
        </el-col>
        <el-col :span="24" v-if="form.percent">
            <el-alert
              title="Maksimum Limit"
              type="info"
              :description="10000 / 100 * form.percent"
              show-icon>
            </el-alert>
        </el-col>
          <el-col :span="24">
            <el-form-item style="margin-top:15px">
              <el-button style="width:100%" :loading="loading" @click="isCreate ? create() : patch()" type="primary">
                <i class="el-icon-circle-check"> </i>
                İşlemi Tamamla</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
  </el-dialog>
  <el-alert
    title="Bilgilendirme"
    type="info"
    description="Tüm alanlarda standart limit tutarı 10.000'dir."
    show-icon>
  </el-alert>
  <br>
  <el-button v-ripple  size="medium" type="primary" @click="openModal('create')">Yeni Limit Oluştur</el-button>
  <br><br>
  <el-table
        v-loading="loading"
        :fixed="false"
        border
        :data="userData"
        style="width: 100%">
        <el-table-column
          label="ID" width="70">
          <template slot-scope="scope">
            #{{ scope.row.id }}
          </template>
        </el-table-column>
        <el-table-column
          label="Tür" width="150">
          <template slot-scope="scope">
            <slot v-if="scope.row.type == 0"> Spor Limitleme</slot>
            <slot v-else-if="scope.row.type == 1"> Kategori Limitleme</slot>
            <slot v-else-if="scope.row.type == 2"> Turnuva Limitleme</slot>
            <slot v-else-if="scope.row.type == 3"> Maç Limitleme</slot>
          </template>
        </el-table-column>
        <el-table-column
          label="Alan" width="100">
          <template slot-scope="scope">
            <slot v-if="scope.row.bet_type == 0">
              Prematch
            </slot>
            <slot v-else>
              Live
            </slot>
          </template>
        </el-table-column>
        <el-table-column
          label="Limitleme" >
          <template slot-scope="scope">
            {{ scope.row.sport.translations.tr.value }}
            <span v-if="scope.row.category">> {{ scope.row.category.translations.tr.value }}</span>
            <span v-if="scope.row.tournament">> {{ scope.row.tournament.translations.tr.value }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="Limit" width="100">
          <template slot-scope="scope">
            10000
          </template>
        </el-table-column>
        <el-table-column
          label="Yüzde" width="100">
          <template slot-scope="scope">
            %{{ scope.row.percent }}
          </template>
        </el-table-column>
        <el-table-column
          label="Maksimum Limit" width="120">
          <template slot-scope="scope">
            {{ 10000 / 100 * scope.row.percent }}
          </template>
        </el-table-column>
        <el-table-column
          label="Sport ID" width="100">
          <template slot-scope="scope">
            {{ scope.row.sport_id }}
          </template>
        </el-table-column>
        <el-table-column
          label="Category ID" width="100">
          <template slot-scope="scope">
            {{ scope.row.category_id }}
          </template>
        </el-table-column>
        <el-table-column
          label="Turnuva ID" width="100">
          <template slot-scope="scope">
            {{ scope.row.tournament_id }}
          </template>
        </el-table-column>
        <el-table-column
          label="Maç ID" width="100">
          <template slot-scope="scope">
            {{ scope.row.match_id }}
          </template>
        </el-table-column>
        <el-table-column
          label="İşlem" width="100">
          <template slot-scope="scope">
            <!-- <el-button v-ripple  type="default" size="small" @click="openModal('edit', scope.row)"> Düzenle</el-button> -->
            <el-button v-ripple  type="danger" size="small" @click="removeItem(scope.row.id)"> Sil</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block pagination">
        <el-pagination
            v-if="paginationFilter.pageTotal > 0"
            @size-change="handleSizeChange"
            @current-change="fetch"
            :current-page.sync="paginationFilter.page"
            :page-size="paginationFilter.size"
            layout="sizes, prev, pager, next"
            :total="paginationFilter.pageTotal"
        ></el-pagination>
      </div> 
  </div>
</template> 
<script>
  export default {
    data() {
      return {
        select_id:null,
        modal: false,
        isCreate:false,
        form:{
          sport_id: null,
          category_id: null,
          tournament_id: null,
          match_id: null,
          percent: null,
          type: 0,
          bet_type: 0,
        },
        filter: {
          id: this.$route.query.id ? this.$route.query.id : null,
          bet_type: this.$route.query.bet_type ? this.$route.query.bet_type : null,
          sport_id: this.$route.query.sport_id ? this.$route.query.sport_id : null,
          tournament_id: this.$route.query.tournament_id ? this.$route.query.tournament_id : null,
          category_id: this.$route.query.category_id ? this.$route.query.category_id : null,
          match_id: this.$route.query.match_id ? this.$route.query.match_id : null,
        },
        paginationFilter:{
          pageTotal: 0,
          size: this.$route.query.size ? parseInt(this.$route.query.size) : 20,
          sort: null,
          page: this.$route.query.page ? parseInt(this.$route.query.page) : 1,
        },
        loading: false,
        userData: [],
      }
    },
    computed:{
      client_id(){
        return this.$user_client_id()
      }
    },
    watch:{
        "form.sport_id" : function(val){
            this.form.category_id = null
            this.form.match_id = null
            this.form.tournament_id = null
        },
        "form.category_id" : function(val){
            this.form.tournament_id = null
            this.form.match_id = null
        },
        // "filter.types": function(val) {
        //   this.fetch(1, this.paginationFilter.size);
        // },
    },
    created(){
      this.fetch();
    },
    methods: {
      removeItem(id){
        this.loading = true;
        axios.delete('/limit-coupons/' + id)
        .then((response) => {
            if(response.status){
              this.$store.dispatch('alert/message', { type: 'success', title: 'Başarılı!'});
              this.modal = false
              this.fetch();
            }else{
              this.$store.dispatch('alert/message', { type: 'error', title: response.data.message });
            }
        })
        .catch((err) => {
        })
        .finally(()=>{
            this.loading = false;
        })
      },
      create(){
        this.loading = true;
        axios.post('/limit-coupons', { ...this.form })
        .then((response) => {
            if(response.status){
              this.$store.dispatch('alert/message', { type: 'success', title: 'Başarılı!'});
              this.modal = false
              this.fetch();
            }else{
              this.$store.dispatch('alert/message', { type: 'error', title: response.data.message });
            }
        })
        .catch((err) => {
        })
        .finally(()=>{
            this.loading = false;
        })
      },
      patch(){
        this.loading = true;
        axios.patch('/limit-coupons/' + this.form.id, { ...this.form })
        .then((response) => {
            if(response.status){
              this.modal = false
              this.$store.dispatch('alert/message', { type: 'success', title: 'Başarılı!'});
              this.fetch();
            }else{
              this.$store.dispatch('alert/message', { type: 'error', title: response.data.message });
            }
        })
        .catch((err) => {
        })
        .finally(()=>{
            this.loading = false;
        })
      },
      openModal(type, form){
        this.modal = true
        if(type == 'create'){
          this.isCreate = true
          this.form.row = null;
          this.form.sport_id = null;
          this.form.category_id = null;
          this.form.tournament_id = true;
          this.form.type = 0;
        }else{
          this.isCreate = false
          this.form.row = form.row;
          this.form.sport_id = form.sport_id;
          this.form.category_id = form.category_id;
          this.form.tournament_id = form.tournament_id;
          this.form.type = form.type;
        }
      },
      handleSizeChange(value){
        this.paginationFilter.size = value;
        this.fetch();
      },
      filterBtn(){
        this.fetch();
      },
      clearBtn(){
        this.filter.id = null;
        this.filter.bet_type = null;
        this.filter.sport_id = null;
        this.filter.category_id = null;
        this.filter.tournament_id = null;
        this.filter.match_id = null;
        this.paginationFilter.sort = null;
        this.fetch();
      },
      fetch(){
        this.loading = true;
        this.$router.replace({ query: { search: this.filter.search, page: this.paginationFilter.page, size: this.paginationFilter.size }});
        axios.get('/limit-coupons', { params: { ...this.paginationFilter, ...this.filter } })
        .then((response) => {
          this.userData = response.data.data;
          this.paginationFilter.pageTotal  	= response.data.meta.total;
        })
        .catch((err) => {
        })
        .finally(()=>{
          this.loading = false;
        });
      },
    }
  }
</script>