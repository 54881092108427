<template>
  <div>
      <FilterForm v-loading="loading">
        <template slot="content">
          <el-form :inline="true">
            <el-form-item :style="!isMobile ? 'width: 150px !important;' : ''">
              <el-input clearable size="medium" v-model="filter.id" placeholder="ID İle Ara"></el-input>
            </el-form-item>
            <el-form-item :style="!isMobile ? 'width: 150px !important;' : ''">
              <el-input clearable size="medium" v-model="filter.username" placeholder="Kullanıcı Adı İle Ara"></el-input>
            </el-form-item>
            <el-form-item :style="!isMobile ? 'width: 150px !important;' : ''">
              <el-input clearable size="medium" v-model="filter.email" placeholder="Email İle Ara"></el-input>
            </el-form-item>
            <el-form-item :style="!isMobile ? 'width: 100px !important;' : ''">
              <el-button v-ripple  size="medium" type="primary" @click="filterBtn">Ara</el-button>
            </el-form-item>
            <el-form-item :style="!isMobile ? 'width: 100px !important;' : ''">
              <el-button v-ripple  size="medium" type="info" @click="clearBtn">Temizle</el-button>
            </el-form-item>
          </el-form>
        </template>
      </FilterForm>
      <div class="block pagination">
        <el-pagination
            v-if="paginationFilter.pageTotal > 0"
            @size-change="handleSizeChange"
            @current-change="fetch"
            :current-page.sync="paginationFilter.page"
            :page-size="paginationFilter.size"
            layout="sizes, prev, pager, next"
            :total="paginationFilter.pageTotal"
        ></el-pagination>
      </div> 
      <el-table
        v-loading="loading"
        :fixed="false"
        border
        :data="historyData"
        style="width: 100%">
        <el-table-column
          label="ID" width="70">
          <template slot-scope="scope">
            #{{ scope.row.id }}
          </template>
        </el-table-column>
        <el-table-column
          label="Kullanıcı Adı">
          <template slot-scope="scope">
            {{ scope.row.username }}
          </template>
        </el-table-column>
        <el-table-column
          label="Email">
          <template slot-scope="scope">
            {{ scope.row.email }}
          </template>
        </el-table-column>
        <el-table-column
          label="Ülke">
          <template slot-scope="scope">
            {{ scope.row.country }}
          </template>
        </el-table-column>
        <el-table-column
          label="Detaylar" width="120">
          <template slot-scope="scope">
            <!-- <el-button v-ripple type="info" size="small" @click="$router.push({ name : 'UserDetail', params:{ id: scope.row.id}})">Düzenle</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <div class="block pagination">
        <el-pagination
            v-if="paginationFilter.pageTotal > 0"
            @size-change="handleSizeChange"
            @current-change="fetch"
            :current-page.sync="paginationFilter.page"
            :page-size="paginationFilter.size"
            layout="sizes, prev, pager, next"
            :total="paginationFilter.pageTotal"
        ></el-pagination>
      </div> 
  </div>
</template> 
<script>
  export default {
    data() {
      return {
        select_id:null,
        modalDetail: false,
        filter: {
          id: this.$route.query.id ? this.$route.query.id : null,
          username: this.$route.query.username ? this.$route.query.username : null,
          email: this.$route.query.email ? this.$route.query.email : null,
        },
        paginationFilter:{
          pageTotal: 0,
          size: this.$route.query.size ? parseInt(this.$route.query.size) : 100,
          sort: null,
          page: this.$route.query.page ? parseInt(this.$route.query.page) : 1,
        },
        loading: false,
        historyData: [],
      }
    },
    components:{
    },
    watch:{
        // "filter.date" : function(val){
        //     this.fetch(1, this.paginationFilter.size);
        // },
        // "filter.types": function(val) {
        //   this.fetch(1, this.paginationFilter.size);
        // },
    },
    created(){
      this.fetch();
    },
    computed:{
      user(){
        return this.$user();
      }
    },
    methods: {
      handleSizeChange(value){
        this.paginationFilter.size = value;
        this.fetch();
      },
      filterBtn(){
        this.fetch();
      },
      clearBtn(){
        this.filter.id = null;
        this.filter.username = null;
        this.filter.email = null;
        this.paginationFilter.sort = null;
        this.fetch();
      },
      fetch(){
        this.loading = true;
        this.$router.replace({ query: { search: this.filter.search, page: this.paginationFilter.page, size: this.paginationFilter.size }});
        axios.get('/customers',{ params: { ...this.paginationFilter, ...this.filter } })
        .then((response) => {
          this.historyData = response.data.data;
          this.paginationFilter.pageTotal  	= response.data.meta.total;
        })
        .catch((err) => {
        })
        .finally(()=>{
          this.loading = false;
        });
      },
    }
  }
</script>