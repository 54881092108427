import { render, staticRenderFns } from "./competitors.vue?vue&type=template&id=7c685e5b&"
import script from "./competitors.vue?vue&type=script&lang=js&"
export * from "./competitors.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports