<template>
    <el-card>
        <el-tabs tab-position="left" v-model="tabPosition">
            <el-tab-pane label="Spor" name="1">
              <Sports :langs="langs" v-if="tabPosition == '1'"/>
            </el-tab-pane>
            <el-tab-pane label="Kategori" name="2">
              <Categories :langs="langs" v-if="tabPosition == '2'"/>
            </el-tab-pane>
            <el-tab-pane label="Turnuva" name="3">
              <Tournaments :langs="langs"  v-if="tabPosition == '3'"/>
            </el-tab-pane>
            <el-tab-pane label="Marketler" name="4">
              <Markets :langs="langs"  v-if="tabPosition == '4'"/>
            </el-tab-pane>
            <!----<el-tab-pane label="Teams" name="4">
              <Competitors :langs="langs"/>
            </el-tab-pane>!-->
        </el-tabs>
    </el-card>
  </template>
  
  
  <script>
    import Sports from '../components/Translations/sports'
    import Tournaments from '../components/Translations/tournaments'
    import Competitors from '../components/Translations/competitors'
    import Categories from '../components/Translations/categories'
    import Markets from '../components/Translations/markets'
    
    export default{
      data() {
        return {
          tabPosition:"1",
          langs:[],
        };
      },
      components:{
        Sports,
        Tournaments,
        Competitors,
        Markets,
        Categories
      },
      created(){
        this.fetch();
      },
      methods: {
          fetch(){
            this.loading = true;
            axios.get('/translations/languages')
            .then((response) => {
              this.langs = response.data.data;
            })
            .catch((err) => {
            })
            .finally(()=>{
              this.loading = false;
            });
          },
      }
    };
  </script>