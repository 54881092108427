<template>
    <div>   
      <el-table
        v-loading="loading"
        :fixed="false"
        :data="sports"
        style="width: 100%">
        <el-table-column
          label="KATEGORİ ID"
          width="100">
          <template slot-scope="scope">
            #{{ scope.row.id }}
          </template>
        </el-table-column>
        <el-table-column label="Diller">
            <el-table-column
            :key="langKey"
            v-for="item,langKey in langs"
            :label="item.code">
            <template slot-scope="scope">
                <el-input @blur="changeEvent(scope.row.id, item.id, $event);" v-model="scope.row.translations[item.code].value"> </el-input>
            </template>
            </el-table-column>
        </el-table-column>
      </el-table>
      <div class="block pagination">
        <el-pagination
            v-if="paginationFilter.pageTotal > 0"
            @size-change="handleSizeChange"
            @current-change="fetch"
            :current-page.sync="paginationFilter.page"
            :page-size="paginationFilter.size"
            layout="sizes, prev, pager, next"
            :total="paginationFilter.pageTotal"
        ></el-pagination>
      </div> 
    </div>
</template>
<script>
  export default{
    props:['langs'],
    data() {
      return {
        sports:[],
        loading:false,
        paginationFilter:{
          pageTotal: 0,
          size: this.$route.query.size ? parseInt(this.$route.query.size) : 20,
          sort: null,
          page: this.$route.query.page ? this.$route.query.page : 1,
        },
      };
    },
    created(){
      this.fetch();
    },
    methods: {
        changeEvent(id, lang_id, event){
            axios.post('translations/category/' + id, { value: event.target.value, language_id: lang_id})
            .then((response) => {
                if(response.status){
                    this.$store.dispatch('alert/message', { type: 'success', title: event.target.value + ' olarak değiştirildi.' });

                }else{
                    this.$store.dispatch('alert/message', { type: 'error', title: response.data.message });
                }
            })
            .catch((err) => {
            })
            .finally(()=>{
                this.loading = false;
            })
        },
        handleSizeChange(value){
            this.paginationFilter.size = value;
            this.fetch();
        },
        fetch(){
            this.loading = true;
            axios.get('/categories?is_paginate=1', { params: { ...this.paginationFilter } })
            .then((response) => {
                this.sports = response.data.data;
                this.paginationFilter.pageTotal  	= response.data.meta.total;
            })
            .catch((err) => {
            })
            .finally(()=>{
            this.loading = false;
            });
        },
    }
  };
</script>