import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import FilterForm from './components/Common/FilterForm'
import Pagination from './components/Common/Pagination'
import SelectClient from './components/Common/SelectClient'
import SelectCurrency from './components/Common/SelectCurrency'
import SelectSport from './components/Common/SelectSport'
import SelectCategory from './components/Common/SelectCategory'
import SelectTournament from './components/Common/SelectTournament'
import SelectMatch from './components/Common/SelectMatch'
import ElementUI from "element-ui";
import locale from 'element-ui/lib/locale/lang/en'
import Notifications from 'vue-notification';
import VueMoment from 'vue-moment'
import CKEditor from 'ckeditor4-vue';
import VueMeta from 'vue-meta'
import i18n from "./plugins/i18n";
import vRipple from "./directives/vRipple";

i18n.locale = store.getters['language/getLanguage'];

Vue.directive("ripple", vRipple);

Vue.component('SelectClient', SelectClient);
Vue.component('SelectCurrency', SelectCurrency);


Vue.component('SelectSport', SelectSport);
Vue.component('SelectCategory', SelectCategory);
Vue.component('SelectTournament', SelectTournament);
Vue.component('SelectMatch', SelectMatch);

Vue.component('FilterForm', FilterForm);
Vue.component('Pagination', Pagination);

Vue.use(ElementUI, { locale })
Vue.use( CKEditor );

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});

window.axios = require('axios');
window.axios.defaults.baseURL = process.env.VUE_APP_API_URL; 
window.axios.defaults.headers.common = {
  'Accept': 'application/json',
  'Authorization': 'Bearer ' +  store.getters['auth/token'],
  'X-Requested-With': 'XMLHttpRequest',
};

window.axios.interceptors.response.use((response) => {
  return response
},
function (error) {
  if (error.response.status === 401) {
    store.dispatch('alert/alert', { type: 'error', title: error.response.data.message})
    store.dispatch('auth/logout', { silent: true })
  } else if (error.response.status === 422 || error.response.status === 429 || error.response.status === 403) {
    store.dispatch('alert/alert', { type: 'error', errors: error.response.data.errors, title: error.response.data.message });
  } else{
    store.dispatch('alert/alert', { type: 'error', title: 'whoops' });
  }
  return Promise.reject(error);
});

Vue.use(Notifications);

const moment = require('moment')
require('moment/locale/tr')
Vue.use(require('vue-moment'), {moment})

window._ = require("lodash");
Vue.prototype._ = window._;

import './assets/scss/app.scss'

Vue.filter('toDouble', function (value) {
  value = parseFloat(value).toFixed(2);
  return isNaN(value) ? 0 : value;
});

Vue.prototype.$user = function() {
  return store.getters['auth/user'];
}
Vue.prototype.$user_detail = function() {
    return store.getters['auth/user_detail'];
}
Vue.prototype.$user_client_id = function() {
    return store.getters['auth/user'].client_id;
}
Vue.prototype.$auth = function() {
  return store.getters['auth/isLoggedIn'];
}
Vue.prototype.$generateFilter = function(filter) {
  var query ='?';

  _.forEach(filter, function(value, key) {
    if(value != null || !_.isEmpty(value)){
      query += `filter[${key}]=${value}&`
    }
  });
  return query
}

// MOBILE DETECT
import { isMobile } from 'mobile-device-detect';
Vue.prototype.isMobile = isMobile

new Vue({
  router,
  store,
  i18n,
  render: function (h) { return h(App) }
}).$mount('#app')
