<template>
    <div class="detail" v-if="user">
      <slot v-if="!isMobile">
        <el-dropdown style="width:100px; margin-right:15px;">
          <el-button v-ripple  type="primary" round style="width: 100%;0px;">
            <span style="justify-content:center;  display:flex; align-items:center">
              <img style="margin-right:5px; border-radius:100%" :src="require('../../../assets/images/langs/' + $i18n.locale + '.jpg')" width="16">
            {{ $t('languages.' + $i18n.locale) }} <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item 
            v-for="(item, key) in lang" :key="key" v-show="$i18n.locale != item" 
            @click.native="$i18n.locale = item; $store.dispatch('language/setLanguage', item)">{{ $t('languages.' + item) }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <div class="user" style="color:#000; opacity:1; text-decoration:none" >
          <b> <i style="color:#000; opacity:1;" class="el-icon-user"></i> {{ user.email }}</b>
        </div>
      </slot>
      <slot v-else>
          <el-row :type="!isMobile ? 'flex' : ''" :gutter="10">
            <el-col :xs="24" :span="4">
              <el-row :gutter="10">
                <el-col :xs="24">
                  <el-card>
                    <h5>Welcome</h5>
                    <b> {{ user.email }}</b>
                  </el-card>
                </el-col>
                <el-col :xs="24">
                      <el-button v-ripple  @click.native="logout" type="default" style="width: 100%;padding:15px 0px;">
                        Log Out
                      </el-button>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
      </slot>
    </div>
</template>
<script>
  export default {
    data() {
      return {
        clients:[{name:'e', id: 1}],
        client_id: 1,
      };
    },
    computed:{
      user(){
        return this.$user();
      },
      lang(){
        return this.$store.getters.getLanguages;
      },
    },
    created(){
    },
    methods:{
      toClient(){
        alert(this.client_id)
      },
      logout(){
          this.$store.dispatch('auth/logout');
					this.$parent.$emit('triggerPanel', '');
      },
    }
  };
</script>
