<template>
    <div>   
      <el-table
        v-loading="loading"
        :fixed="false"
        :data="outcomes"
        style="width: 100%">
        <el-table-column
          label="Market ID"
          width="100">
          <template slot-scope="scope">
            #{{ scope.row.id }}
          </template>
        </el-table-column>
        <el-table-column label="Diller">
            <el-table-column
            :key="langKey"
            v-for="item,langKey in langs"
            :label="item.code">
            <template slot-scope="scope">
                <el-input v-if="scope.row.translations && scope.row.translations[item.code] && scope.row.translations[item.code].value" @blur="changeEvent(scope.row.id, item.id, $event, item.code);" v-model="scope.row.translations[item.code].value"> </el-input>
                <div class="el-input" v-else>
                  <input class="el-input__inner" type="text" @blur="changeEvent(scope.row.id, item.id, $event, item.code);">  
                </div>
            </template>
            </el-table-column>
        </el-table-column>
      </el-table>
      <div class="block pagination">
        <el-pagination
            v-if="paginationFilter.pageTotal > 0"
            @size-change="handleSizeChange"
            @current-change="fetch"
            :current-page.sync="paginationFilter.page"
            :page-size="paginationFilter.size"
            layout="sizes, prev, pager, next"
            :total="paginationFilter.pageTotal"
        ></el-pagination>
      </div> 
    </div>
</template>
<script>
  export default{
    props:['langs','sportId', 'marketId', 'marketType'],
    data() {
      return {
        outcomes:[],
        loading:false,
        paginationFilter:{
          sport_id: 250,
          market_id: 0,
          type:0,
          pageTotal: 0,
          size: this.$route.query.size ? parseInt(this.$route.query.size) : 20,
          sort: null,
          page: this.$route.query.page ? this.$route.query.page : 1,
        },
      };
    },
    watch:{
    },
    created(){
      this.paginationFilter.sport_id = this.sportId;
      this.paginationFilter.market_id = this.marketId;
      this.paginationFilter.type = this.marketType;
      this.fetch();
    },
    methods: {
        changeEvent(id, lang_id, event, code){
          if(!_.isEmpty(event.target.value)){
            

            axios.post('translations/' + (this.paginationFilter.type == 0 ? 'spec' : 'translatemarketodd') + '/' + id, { value: event.target.value, language_id: lang_id})
            .then((response) => {
                if(response.status){
                    this.$store.dispatch('alert/message', { type: 'success', title: event.target.value + ' olarak değiştirildi.' });

                }else{
                    this.$store.dispatch('alert/message', { type: 'error', title: response.data.message });
                }
            })
            .catch((err) => {
            })
            .finally(()=>{
                this.loading = false;
            })
          }else{
            return false
          }
        },
        handleSizeChange(value){
            this.paginationFilter.size = value;
            this.fetch();
        },
        fetch(){
            this.loading = true;
            axios.get('/markets/spec', { params: { ...this.paginationFilter } })
            .then((response) => {
                this.outcomes = response.data.data;
                this.paginationFilter.pageTotal  	= response.data.meta.total;
            })
            .catch((err) => {
            })
            .finally(()=>{
            this.loading = false;
            });
        },
    }
  };
</script>