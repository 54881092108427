<template>
  <el-select
    v-model="content"
    multiple
    collapse-tags
    @input="handleInput"
    style="width:100%"
    placeholder="Select">
    <el-option
      v-for="item in data"
      :key="item.id"
      :label="item.name"
      :value="item.id">
    </el-option>
  </el-select>
</template>
<script>
  export default {
    props:['value'],
    data() {
      return {
        data:[],
        content:this.value,
      }
    },
    created(){
      this.fetch();
    },
    methods:{
      handleInput (e) {
        this.$emit('input', this.content)
      },
      fetch(){
        this.loading = true;
        axios.get('/sports')
        .then((response) => {
          this.data = response.data.data;
        })
        .catch((err) => {
        })
        .finally(()=>{
            this.loading = false;
        })
      },
    },
    watch:{
      value(val){
        this.content = val
      }
    },
  }
</script>