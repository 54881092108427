<template>
  <div id="app" :class="[{'mobile' : isMobile}, activePanel, _.toLower($route.name)]">
    <slot v-if="auth">
      <MobilePanelLeft @triggerPanel="activePanel = $event" v-if="isMobile"/>
      <MobilePanelRight v-show="activePanel == 'right-panel'" @triggerPanel="activePanel = $event" v-if="isMobile"/>
    </slot>
    <div class="inc">
      <Menu v-if="!isMobile && auth" />
      <el-container class="is-vertical">
          <Header v-if="auth" :activePanel="activePanel" @triggerPanel="activePanel = $event"></Header>
          <div v-if="auth" v-show="activePanel == 'left-panel'" @click="activePanel = ''" class="overlay"></div>
          <el-main v-show="activePanel != 'right-panel'"><router-view/></el-main>
      </el-container>
    </div>
  </div>
</template>

<script>
  import Menu from './components/Common/Header/Menu'
  import Header from '@/components/Common/Header';
  import MobilePanelLeft from '@/components/Common/Header/MobilePanelLeft';
  import MobilePanelRight from '@/components/Common/Header/MobilePanelRight';
  export default{
    data(){
      return{
        activePanel: '',
      }
    },
    components:{
      Header,
      Menu,
      MobilePanelLeft,
      MobilePanelRight,
    },
    computed:{
      auth(){
          return this.$auth();
      },
    },
    created(){
      if(this.auth){
        // this.$store.dispatch('translate/fetchSports');
        // this.$store.dispatch('translate/fetchTournaments');
        // this.$store.dispatch('translate/fetchCategories');
      }
    },
    watch:{
    },
  }
</script>
