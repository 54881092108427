<template>
  <div class="mobile-app-widgets">
        <Right/>
  </div>
</template>

<script>
  import Right from './Right'
  export default{
    data(){
      return{
      }
    },
    components:{
      Right
    },
		methods:{
				triggerPanel(panel){
					this.$emit('triggerPanel', panel)
				}
		}
  }
</script>