<template>
  <el-row :gutter="15">
      <el-col :span="16" v-loading="loading">
        <FilterForm>
        <template slot="content">
          <el-form :inline="true">
            <el-form-item :style="!isMobile ? 'width: 150px !important;' : ''">
              <el-input clearable size="medium" v-model="filter.coupon_id" placeholder="Kupon ID İle Ara"></el-input>
            </el-form-item>
            <el-form-item :style="!isMobile ? 'width: 150px !important;' : ''">
              <el-select size="medium" v-model="filter.status" style="width:100%" placeholder="Kupon Durumu Seçiniz"> 
                <el-option :key="0" label="Bekliyor" :value="0"> </el-option>
                <el-option :key="1" label="Kazandı" :value="1"> </el-option>
                <el-option :key="2" label="Kaybetti" :value="2"> </el-option>
                <el-option :key="3" label="İşleniyor" :value="3"> </el-option>
                <el-option :key="4" label="İptal oldu" :value="4"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item :style="!isMobile ? 'width: 150px !important;' : ''">
              <el-select size="medium" v-model="filter.bet_type" style="width:100%" placeholder="Kupon Tipi Seçiniz"> 
                <el-option :key="0" label="Tekli" :value="0"> </el-option>
                <el-option :key="1" label="Kombine" :value="1"> </el-option>
                <el-option :key="2" label="Sistem" :value="2"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item :style="!isMobile ? 'width: 80px !important;' : ''">
              <el-button v-ripple  size="medium" type="primary" @click="filterBtn">Ara</el-button>
            </el-form-item>
            <el-form-item :style="!isMobile ? 'width: 80px !important;' : ''">
              <el-button v-ripple  size="medium" type="info" @click="clearBtn">Temizle</el-button>
            </el-form-item>
          </el-form>
        </template>
        </FilterForm>
        <div class="block pagination">
          <el-pagination
              v-if="paginationFilter.pageTotal > 0"
              @size-change="handleSizeChange"
              @current-change="fetch"
              :current-page.sync="paginationFilter.page"
              :page-size="paginationFilter.size"
              layout="sizes, prev, pager, next"
              :total="paginationFilter.pageTotal"
          ></el-pagination>
        </div> 
        <el-table
          :fixed="false"
          border
          :data="couponData"
          style="width: 100%">
          <el-table-column
            label="ID" width="70">
            <template slot-scope="scope">
              #{{ scope.row.id }}
            </template>
          </el-table-column>
          <el-table-column
            label="Client ID" width="70">
            <template slot-scope="scope">
              #{{ scope.row.client_id }}
            </template>
          </el-table-column>
          <el-table-column
            label="Customer ID" width="90">
            <template slot-scope="scope">
              #{{ scope.row.customer_id }}
            </template>
          </el-table-column>
          <el-table-column
            label="Kupon Türü">
            <template slot-scope="scope">
              <b>{{ $t('coupon.bet_type.' + scope.row.bet_type) }} </b>
            </template>
          </el-table-column>
          <el-table-column
            label="Tarih" width="100">
            <template slot-scope="scope">
              {{ $moment(scope.row.created_at).format('DD/MM H:mm') }}
            </template>
          </el-table-column>
          <el-table-column
            label="Bahis">
            <template slot-scope="scope">
              {{ scope.row.amount }} {{ scope.row.currency }}
            </template>
          </el-table-column>
          <el-table-column
            label="M. Kazanç">
            <template slot-scope="scope">
              {{ scope.row.estimated_amount }} {{ scope.row.currency }}
            </template>
          </el-table-column>
          <el-table-column
            label="Durumu">
            <template slot-scope="scope">
              <div class="status" :class="'status_' + scope.row.status">
                {{ $t('coupon.status.' + scope.row.status) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="Detaylar">
            <template slot-scope="scope">
              <el-button v-ripple @click="open_coupon(scope.row)"  type="info" size="small">
                <i class="el-icon-view"></i>
                Gör
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="block pagination">
          <el-pagination
              v-if="paginationFilter.pageTotal > 0"
              @size-change="handleSizeChange"
              @current-change="fetch"
              :current-page.sync="paginationFilter.page"
              :page-size="paginationFilter.size"
              layout="sizes, prev, pager, next"
              :total="paginationFilter.pageTotal"
          ></el-pagination>
        </div>
    </el-col> 
    <el-col :span="8" v-if="!_.isNull(activeCoupon)">
        <el-card class="coupon-card-t" v-bind:class="{classA: scrollPosition < 100, classB: scrollPosition > 100}">
            <div slot="header" class="clearfix">
              <span>#{{ activeCoupon.id }} {{ $t('coupon.bet_type.' + activeCoupon.bet_type) }}</span>
             
            </div>
            <el-row :gutter="5">
              <el-col :span="12">
                <div class="item-info">
                  <div class="left">
                    Tutar
                  </div> 
                  <div class="right">
                    {{ activeCoupon.amount }} {{ activeCoupon.currency }}
                  </div>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="item-info">
                  <div class="left">
                    M. Kazanç
                  </div> 
                  <div class="right">
                    {{ activeCoupon.estimated_amount }} {{ activeCoupon.currency }}
                  </div>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="item-info">
                  <div class="left">
                    Tarih
                  </div> 
                  <div class="right">
                    {{ $moment(activeCoupon.created_at).format('DD/MM H:mm') }}
                  </div>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="item-info">
                  <div class="left">
                    Kupon
                  </div> 
                  <div class="right">
                    {{ $t('coupon.status.' + activeCoupon.status) }}
                  </div>
                </div>
              </el-col>
            </el-row>
            <el-card class="coupon-card" :class="'border_status_' + event.status" v-for="(event,eventKey) in activeCoupon.details" :key="eventKey">
                <small class="time">
                  {{ $moment(event.match.date).format('DD/MM H:mm') }}
                </small>
                <div class="event">
                  <slot v-if="!_.isNull(event.match.hometeam.translates)">
                    {{ event.match.hometeam.translates.en }}
                  </slot>  
                  V
                  <slot v-if="!_.isNull(event.match.awayteam.translates)">
                    {{ event.match.awayteam.translates.en }}
                  </slot>  
                </div>
                <div class="info">
                  <div class="left">
                    Market:
                  </div>
                  <div class="right">
                    <div class="market">
                      <slot v-if="!_.isNull(event.market.translates)">
                        {{ event.market.translates.en }}
                      </slot>  
                      {{ event.market.specialoddsvalue }}
                    </div>
                  </div>
                </div>
                <div class="info">
                  <div class="left">
                    Bahis:
                  </div>
                  <div class="right">
                    <div class="odd">
                        <slot v-if="!_.isNull(event.odd.translates)">
                          {{ event.odd.translates.en }}
                        </slot>  
                        <span class="rate">
                          {{ event.odd.value }}
                        </span>
                    </div>
                  </div>
                </div>
                <div class="status" :class="'status_' + event.status">
                  {{ $t('coupon.status.' + event.status) }}
                </div>
            </el-card>
        </el-card>
    </el-col>
  </el-row>
</template> 
<script>
  export default {
    data() {
      return {
        select_id:null,
        modalDetail: false,
        filter: {
          id: this.$route.query.id ? this.$route.query.id : null,
          status: this.$route.query.status ? this.$route.query.status : null,
          bet_type: this.$route.query.bet_type ? this.$route.query.bet_type : null,
        },
        paginationFilter:{
          pageTotal: 0,
          size: this.$route.query.size ? parseInt(this.$route.query.size) : 20,
          sort: null,
          page: this.$route.query.page ? this.$route.query.page : 1,
        },
        loading: false,
        activeCoupon:null,
        couponData: [],
        scrollPosition: null
      }
    },
    components:{
    },
    watch:{
        // "filter.date" : function(val){
        //     this.fetch(1, this.paginationFilter.size);
        // },
        // "filter.types": function(val) {
        //   this.fetch(1, this.paginationFilter.size);
        // },
    },
    created(){
      this.fetch();
    },
    computed:{
      user(){
        return this.$user();
      }
    },
    mounted() {
      window.addEventListener('scroll', this.updateScroll);
    },
    methods: {
      updateScroll() {
        this.scrollPosition = window.scrollY
      },
      open_coupon(form){
        this.activeCoupon = form
      },
      handleSizeChange(value){
        this.paginationFilter.size = value;
        this.fetch();
      },
      filterBtn(){
        this.fetch();
      },
      clearBtn(){
        this.filter.id = null;
        this.filter.status = null;
        this.filter.bet_type = null;
        this.paginationFilter.sort = null;
        this.fetch();
      },
      fetch(){
        this.loading = true;
        this.$router.replace({ query: { search: this.filter.search, page: this.paginationFilter.page, size: this.paginationFilter.size }});
        axios.get('/coupons',{ params: { ...this.paginationFilter, ...this.filter } })
        .then((response) => {
          this.couponData = response.data.data;
          this.paginationFilter.pageTotal  	= response.data.meta.total;
        })
        .catch((err) => {
        })
        .finally(()=>{
          this.loading = false;
        });
      },
    }
  }
</script>