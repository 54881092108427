<template>
  <div>
      <FilterForm v-loading="loading">
        <template slot="content">
          <el-form :inline="true">
            <el-form-item :style="!isMobile ? 'width: 150px !important;' : ''">
              <el-input clearable size="medium" v-model="filter.id" placeholder="ID İle Ara"></el-input>
            </el-form-item>
            <el-form-item :style="!isMobile ? 'width: 150px !important;' : ''">
              <el-input clearable size="medium" v-model="filter.username" placeholder="Kullanıcı Adı İle Ara"></el-input>
            </el-form-item>
            <el-form-item :style="!isMobile ? 'width: 150px !important;' : ''">
              <el-input clearable size="medium" v-model="filter.email" placeholder="Email İle Ara"></el-input>
            </el-form-item>
            <el-form-item :style="!isMobile ? 'width: 100px !important;' : ''">
              <el-button v-ripple  size="medium" type="primary" @click="filterBtn">Ara</el-button>
            </el-form-item>
            <el-form-item :style="!isMobile ? 'width: 100px !important;' : ''">
              <el-button v-ripple  size="medium" type="info" @click="clearBtn">Temizle</el-button>
            </el-form-item>
          </el-form>
        </template>
      </FilterForm>
      <div class="block pagination">
        <el-pagination
            v-if="paginationFilter.pageTotal > 0"
            @size-change="handleSizeChange"
            @current-change="fetch"
            :current-page.sync="paginationFilter.page"
            :page-size="paginationFilter.size"
            layout="sizes, prev, pager, next"
            :total="paginationFilter.pageTotal"
        ></el-pagination>
      </div> 
    <el-dialog
      :title="isCreate ? 'Yeni Admin Oluştur' : 'Düzenle'"
      :visible.sync="modal"
      width="30%">
      <el-form label-position="top" v-if="!_.isEmpty(form)">
        <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="İsim">
            <el-input  autocomplete="off" required placeholder="İsim" v-model="form.name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="Email">
            <el-input  autocomplete="off" required placeholder="Email" v-model="form.email"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="Client ID">
            <el-input  autocomplete="off" required placeholder="Secret" v-model="form.client_id"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="Aktif">
            <el-switch v-model="form.is_active"></el-switch>
          </el-form-item>
        </el-col>
          <el-col :span="24">
            <el-form-item style="margin-top:15px">
              <el-button style="width:100%" :loading="loading" @click="edit()" type="primary">
                <i class="el-icon-circle-check"> </i>
                İşlemi Tamamla</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
  </el-dialog>
      <el-button v-ripple  size="medium" type="primary" @click="openModal('create')">Yeni Admin Oluştur</el-button>
      <br><br>
      <el-table
        v-loading="loading"
        :fixed="false"
        border
        :data="userData"
        style="width: 100%">
        <el-table-column
          label="ID" width="70">
          <template slot-scope="scope">
            #{{ scope.row.id }}
          </template>
        </el-table-column>
        <el-table-column
          label="Kullanıcı Adı">
          <template slot-scope="scope">
            {{ scope.row.username }}
          </template>
        </el-table-column>
        <el-table-column
          label="Email">
          <template slot-scope="scope">
            {{ scope.row.email }}
          </template>
        </el-table-column>
        <el-table-column
          label="Client ID" width="100">
          <template slot-scope="scope">
            {{ scope.row.client_id }}
          </template>
        </el-table-column>
        <el-table-column
          label="Durumu" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.is_active">Aktif</span>
            <span v-if="!scope.row.is_active">Pasif</span>
          </template>
        </el-table-column>
        <el-table-column
          label="G2FA" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.is_g2fa">Aktif</span>
            <span v-if="!scope.row.is_g2fa">Pasif</span>
          </template>
        </el-table-column>
        <el-table-column
        width=""
          label="Detaylar">
          <template slot-scope="scope">
            <el-button v-ripple  type="default" size="small" @click="openModal('edit', scope.row)"> Düzenle</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block pagination">
        <el-pagination
            v-if="paginationFilter.pageTotal > 0"
            @size-change="handleSizeChange"
            @current-change="fetch"
            :current-page.sync="paginationFilter.page"
            :page-size="paginationFilter.size"
            layout="sizes, prev, pager, next"
            :total="paginationFilter.pageTotal"
        ></el-pagination>
      </div> 
  </div>
</template> 
<script>
  export default {
    data() {
      return {
        select_id:null,
        modal: false,
        isCreate:false,
        form:{
          name:null,
          email: null,
          client_id: null,
          is_active: null,
        },
        filter: {
          id: this.$route.query.id ? this.$route.query.id : null,
          username: this.$route.query.username ? this.$route.query.username : null,
          email: this.$route.query.email ? this.$route.query.email : null,
        },
        paginationFilter:{
          pageTotal: 0,
          size: this.$route.query.size ? parseInt(this.$route.query.size) : 100,
          sort: null,
          page: this.$route.query.page ? this.$route.query.page : 1,
        },
        loading: false,
        userData: [],
      }
    },
    components:{
    },
    watch:{
        // "filter.date" : function(val){
        //     this.fetch(1, this.paginationFilter.size);
        // },
        // "filter.types": function(val) {
        //   this.fetch(1, this.paginationFilter.size);
        // },
    },
    created(){
      this.fetch();
    },
    computed:{
      user(){
        return this.$user();
      }
    },
    methods: {
      openModal(type, form){
        this.modal = true
        if(type == 'create'){
          this.isCreate = true
          this.form.name = null;
          this.form.email = null;
          this.form.client_id = null;
          this.form.is_active = true;
        }else{
          this.isCreate = false
          this.form.name = form.name;
          this.form.email = form.email;
          this.form.client_id = form.client_id;
          this.form.is_active = form.is_active == 1 ? true : false;
        }
      },
      handleSizeChange(value){
        this.paginationFilter.size = value;
        this.fetch();
      },
      filterBtn(){
        this.fetch();
      },
      clearBtn(){
        this.filter.id = null;
        this.filter.username = null;
        this.filter.email = null;
        this.paginationFilter.sort = null;
        this.fetch();
      },
      fetch(){
        this.loading = true;
        this.$router.replace({ query: { search: this.filter.search, page: this.paginationFilter.page, size: this.paginationFilter.size }});
        axios.get('/users', { params: { ...this.paginationFilter, ...this.filter } })
        .then((response) => {
          this.userData = response.data.data;
          this.paginationFilter.pageTotal  	= response.data.meta.total;
        })
        .catch((err) => {
        })
        .finally(()=>{
          this.loading = false;
        });
      },
    }
  }
</script>