<template>
  <el-select
    filterable
    v-model="content"
    collapse-tags
    @input="handleInput"
    style="width:100%"
    remote
    :remote-method="fetch"
    placeholder="Kategori Seçiniz">
    <el-option
      v-for="item in data"
      :key="item.id"
      :label="item.translations.tr ? item.translations.tr.value : item.translations.en.value"
      :value="item.id">
    </el-option>
  </el-select>
</template>
<script>
  export default {
    props:['value', 'sport_id'],
    data() {
      return {
        loading:false,
        data:[],
        content:this.value,
      }
    },
    created(){
      this.fetch();
    },
    methods:{
      handleInput (e) {
        this.$emit('input', this.content)
      },
      fetch(query){
        this.loading = true;
        axios.get('/categories', { params: { search: query, sport_id : this.sport_id } })
        .then((response) => {
          this.data = response.data.data;
        })
        .catch((err) => {
        })
        .finally(()=>{
            this.loading = false;
        })
      },
    },
    watch:{
      sport_id(){
        this.fetch();
      },
      value(val){
        this.content = val
      }
    },
  }
</script>