<template>
  <div>
      <FilterForm v-loading="loading">
        <template slot="content">
          <el-form :inline="true">
            <el-form-item :style="!isMobile ? 'width: 150px !important;' : ''">
              <el-input clearable size="medium" v-model="filter.id" placeholder="ID İle Ara"></el-input>
            </el-form-item>
            <el-form-item :style="!isMobile ? 'width: 150px !important;' : ''">
              <SelectSport :size="'medium'" v-model="filter.sport_id"></SelectSport>
            </el-form-item>
            <el-form-item :style="!isMobile ? 'width: 150px !important;' : ''">
              <el-select size="medium" v-model="filter.type" style="width:100%" placeholder="Tür Seçiniz"> 
                <el-option :key="0" label="Prematch" :value="0"> </el-option>
                <el-option :key="1" label="Canlı" :value="1"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item :style="!isMobile ? 'width: 100px !important;' : ''">
              <el-button v-ripple  size="medium" type="primary" @click="filterBtn">Ara</el-button>
            </el-form-item>
            <el-form-item :style="!isMobile ? 'width: 100px !important;' : ''">
              <el-button v-ripple  size="medium" type="info" @click="clearBtn">Temizle</el-button>
            </el-form-item>
          </el-form>
        </template>
      </FilterForm>
      <div class="block pagination">
        <el-pagination
            v-if="paginationFilter.pageTotal > 0"
            @size-change="handleSizeChange"
            @current-change="fetch"
            :current-page.sync="paginationFilter.page"
            :page-size="paginationFilter.size"
            layout="sizes, prev, pager, next"
            :total="paginationFilter.pageTotal"
        ></el-pagination>
      </div> 
      <el-dialog
      :title="isCreate ? 'Yeni Market Grubu Oluştur' : 'Düzenle'"
      :visible.sync="modal"
      width="30%">
      <el-form label-position="top" v-if="!_.isEmpty(form)">
        <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label="TR">
                <el-input  size="medium" v-model="form.name['tr']" placeholder="TR Dilinde"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="EN">
                <el-input  size="medium" v-model="form.name['en']" placeholder="EN Dilinde"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Spor">
                <SelectSport v-model="form.sport_id"></SelectSport>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Tür Seçiniz">
                  <el-select size="medium" v-model="form.type" style="width:100%" placeholder="Tür Seçiniz"> 
                    <el-option :key="0" label="Prematch" :value="0"> </el-option>
                    <el-option :key="1" label="Canlı" :value="1"> </el-option>
                  </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item style="margin-top:15px">
                <el-button style="width:100%" :loading="loading" @click="isCreate ? create() : patch()" type="primary">
                  <i class="el-icon-circle-check"> </i>
                  İşlemi Tamamla</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-dialog>

      <el-dialog
        v-if="selectedGroup"
        :visible.sync="marketModal"
        width="70%">
        
          <h1 style="margin: 0px;" slot="title">{{ _.capitalize(selectedGroup.translations['tr'].value) + ' Grubu'}}

            <el-tag effect="dark" type="primary">{{  selectedGroup.type == 0 ? 'Prematch' : 'Live' }}</el-tag>
          </h1>
        
          <Group :type="selectedGroup.type" :sport_id="selectedGroup.sport.id" :id="selectedGroup.id"/>
      </el-dialog>

      <el-button v-ripple  size="medium" type="primary" @click="openModal('create')">Yeni Market Grubu Oluştur</el-button>
      <br><br>
      <el-table
        v-loading="loading"
        :fixed="false"
        border
        :data="groupData"
        style="width: 100%">
        <el-table-column
          label="ID" width="70">
          <template slot-scope="scope">
            #{{ scope.row.id }}
          </template>
        </el-table-column>
        <el-table-column
          label="Spor" width="150">
          <template slot-scope="scope">
            {{  scope.row.sport.translations['tr'].value }}
          </template>
        </el-table-column>
        <el-table-column
          label="Tür" width="150">
          <template slot-scope="scope">
            <el-tag effect="dark" type="primary">{{  scope.row.type == 0 ? 'Prematch' : 'Live' }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          label="TR">
          <template slot-scope="scope">
            {{  scope.row.translations['tr'].value }}
          </template>
        </el-table-column>
        <el-table-column
          label="EN">
          <template slot-scope="scope">
            {{  scope.row.translations['en'].value }}
          </template>
        </el-table-column>
        
        <el-table-column
          label="İşlem">
          <template slot-scope="scope">
            <el-button v-ripple  type="success" size="small" @click="openModal('edit', scope.row)"> Düzenle</el-button>
            <el-button v-ripple  type="primary" size="small" @click="marketModal = true; selectedGroup = scope.row"> Marketler</el-button>
            <el-popconfirm
              style="margin-left: 10px;"
              confirm-button-text='Sil'
              cancel-button-text='Kapat'
              icon="el-icon-warning"
              @onConfirm="removeItem(scope.row.id)"
              icon-color="red"
              title="Silmek istediğinize emin misiniz?"
            >
                        <el-button v-ripple  type="danger" size="small" slot="reference" > Sil</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <div class="block pagination">
        <el-pagination
            v-if="paginationFilter.pageTotal > 0"
            @size-change="handleSizeChange"
            @current-change="fetch"
            :current-page.sync="paginationFilter.page"
            :page-size="paginationFilter.size"
            layout="sizes, prev, pager, next"
            :total="paginationFilter.pageTotal"
        ></el-pagination>
      </div> 
  </div>
</template> 
<script>
  import Group from '../components/Groups/Group'
  export default {
    data() {
      return {
        select_id:null,
        modal: false,
        marketModal:false,
        selectedGroup:null,
        isCreate:false,
        form:{
          sport_id: null,
          name:{
            tr:null,
            en:null,
          },
          id: null,
          type:null
        },
        filter: {
          type:null,
          sport_id: null,
          id: this.$route.query.id ? this.$route.query.id : null,
        },
        paginationFilter:{
          pageTotal: 0,
          size: this.$route.query.size ? parseInt(this.$route.query.size) : 20,
          sort: null,
          page: this.$route.query.page ? parseInt(this.$route.query.page) : 1,
        },
        loading: false,
        groupData: [],
      }
    },
    components:{
      Group
    },
    computed:{
      client_id(){
        return this.$user_client_id()
      }
    },
    watch:{
        "marketModal" : function(val){
            if(!val){
              this.selectedGroup = null
            }
        },
        "form.sport_id" : function(val){
            this.form.category_id = null
            this.form.tournament_id = null
        },
        "form.category_id" : function(val){
            this.form.tournament_id = null
        },
        "modal": function(val) {
          if(!val){
            this.form.name.tr = null;
            this.form.name.en = null;
            this.form.id = null;
          }
        },
    },
    created(){
      this.fetch();
    },
    methods: {
      removeItem(id){
        this.loading = true;
        axios.delete('/markets/groups/' + id)
        .then((response) => {
            if(response.status){
              this.$store.dispatch('alert/message', { type: 'success', title: 'Başarılı!'});
              this.modal = false
              this.fetch();
            }else{
              this.$store.dispatch('alert/message', { type: 'error', title: response.data.message });
            }
        })
        .catch((err) => {
        })
        .finally(()=>{
            this.loading = false;
        })
      },
      create(){
        this.loading = true;
        axios.post('/markets/groups', { ...this.form })
        .then((response) => {
            if(response.status){
              this.$store.dispatch('alert/message', { type: 'success', title: 'Başarılı!'});
              this.modal = false
              this.fetch();
            }else{
              this.$store.dispatch('alert/message', { type: 'error', title: response.data.message });
            }
        })
        .catch((err) => {
        })
        .finally(()=>{
            this.loading = false;
        })
      },
      patch(){
        this.loading = true;
        axios.patch('/markets/groups/' + this.form.id, { ...this.form })
        .then((response) => {
            if(response.status){
              this.modal = false
              this.$store.dispatch('alert/message', { type: 'success', title: 'Başarılı!'});
              this.fetch();
            }else{
              this.$store.dispatch('alert/message', { type: 'error', title: response.data.message });
            }
        })
        .catch((err) => {
        })
        .finally(()=>{
            this.loading = false;
        })
      },
      openModal(type, form){
        this.modal = true
        if(type == 'create'){
          this.isCreate = true
        }else{
          this.isCreate = false
          this.form.name.tr = form.translations['tr'].value;
          this.form.name.en = form.translations['en'].value;
          this.form.id = form.id;
        }
      },
      handleSizeChange(value){
        this.paginationFilter.size = value;
        this.fetch();
      },
      filterBtn(){
        this.fetch();
      },
      clearBtn(){
        this.filter.type = null;
        this.filter.id = null;
        this.filter.sport_id = null;
        this.fetch();
      },
      fetch(){
        this.loading = true;
        this.$router.replace({ query: { sport_id: this.filter.sport_id, page: this.paginationFilter.page, size: this.paginationFilter.size }});
        axios.get('/markets/groups/0', { params: { ...this.paginationFilter, ...this.filter } })
        .then((response) => {
          this.groupData = response.data.data;
          this.paginationFilter.pageTotal  	= response.data.meta.total;
        })
        .catch((err) => {
        })
        .finally(()=>{
          this.loading = false;
        });
      },
    }
  }
</script>