const state = {
  language: localStorage.getItem("language") || navigator.language.split('-')[0] || process.env.VUE_APP_I18N_LOCALE || 'en'
}
const getters = {
  getLanguage: (state) => state.language
}
const mutations = {
  LANGUAGE_SET(state, language) {
      state.language = language;
      localStorage.setItem("language", language);
      //window.axios.defaults.headers.common.Language = language;
  }
}
const actions = {
  setLanguage: ({ commit }, payLoad) => {
      commit('LANGUAGE_SET', payLoad)
  },
}

export default {
namespaced: true,
state,
getters,
mutations,
actions,
}