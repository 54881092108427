<template>
  <div>
    <el-page-header @back="goBack">
      <div slot="content" class="clearfix">
        <span>#{{ id }}</span>
      </div> 
    </el-page-header>
    <el-card>
      <div slot="header" class="clearfix">
        <span>Hesap Detayları</span>
      </div> 
      <el-tabs :tab-position="tabPosition" v-model="labelName"  style="height: 200px;">
        <el-tab-pane name="1" label="Limitler">
          <slot v-if="name == '1'">

          </slot>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
  export default {
    data(){
      return{
        id:this.$route.params.id,
        labelName:"1",
        tabPosition: 'left'
      }
    },
    methods:{
      goBack(){
        this.$router.push({ name: 'UserList'})
      }
    }
  }
</script>
