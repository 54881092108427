import { Notification, Message } from 'element-ui';

const state = {
};

const getters = {
};

const mutations = {
}

const actions = {
    alert: ({ commit }, msg) => {
        let message = '';
        _.forEach(msg.errors, function(value, key) {
            message += value[0] + "<br>";
        });
        console.log(
            {
                title: msg.title,
                dangerouslyUseHTMLString: true,
                message: message,
                type: msg.type
              }
        )
        Notification({
            title: msg.title,
            dangerouslyUseHTMLString: true,
            message: message,
            type: msg.type
          })
    },
    message: ({ commit }, msg) => {
        Message({
            message: msg.title,
            type: msg.type,
            title: msg.title,
        })
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}