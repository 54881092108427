<template>
    <el-header>
          <div class="header-app-top">
              <div class="info">
                  <a class="nav" v-if="isMobile" @click="triggerPanel('left-panel')" href="javascript:;">
                    <svg class="colored" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512"><path d="m464.883 64.267h-417.766c-25.98 0-47.117 21.136-47.117 47.149 0 25.98 21.137 47.117 47.117 47.117h417.766c25.98 0 47.117-21.137 47.117-47.117 0-26.013-21.137-47.149-47.117-47.149z"/><path d="m464.883 208.867h-417.766c-25.98 0-47.117 21.136-47.117 47.149 0 25.98 21.137 47.117 47.117 47.117h417.766c25.98 0 47.117-21.137 47.117-47.117 0-26.013-21.137-47.149-47.117-47.149z"/><path d="m464.883 353.467h-417.766c-25.98 0-47.117 21.137-47.117 47.149 0 25.98 21.137 47.117 47.117 47.117h417.766c25.98 0 47.117-21.137 47.117-47.117 0-26.012-21.137-47.149-47.117-47.149z"/></svg>
                  </a>
                  <router-link v-if="isMobile" to="/" @click.native="triggerPanel('')">
                    <img src="../../assets/images/logo-white.png" width="200">
                  </router-link>
                  <!-- <div class="find" v-if="!isMobile" >
                     <el-input type="number" clearable size="medium" v-model="search" @keyup.enter.native="toDetail" placeholder="Coupon ID Search"></el-input>
                  </div> -->
              </div>
              <div v-if="isMobile">
                <a @click="triggerPanel('right-panel')" href="javascript:;">
                 <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"> <g> <g> <path d="M437.02,330.98c-27.883-27.882-61.071-48.523-97.281-61.018C378.521,243.251,404,198.548,404,148    C404,66.393,337.607,0,256,0S108,66.393,108,148c0,50.548,25.479,95.251,64.262,121.962    c-36.21,12.495-69.398,33.136-97.281,61.018C26.629,379.333,0,443.62,0,512h40c0-119.103,96.897-216,216-216s216,96.897,216,216    h40C512,443.62,485.371,379.333,437.02,330.98z M256,256c-59.551,0-108-48.448-108-108S196.449,40,256,40    c59.551,0,108,48.448,108,108S315.551,256,256,256z"/> </g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </svg>
                </a>
              </div>
              <Right v-if="!isMobile"/>
          </div>
    </el-header>
</template>
<script>
  import Right from './Header/Right'
  export default {
    data(){
      return{
        search: null
      }
    },
    props:['activePanel'],
    components:{
      Right
    },
		methods:{
        toDetail(data){
          //window.open('/game-detail/' + this.search,'popup', "width=700,height=800");
          this.search = null;
        },
				triggerPanel(panel){
					this.$emit('triggerPanel', panel)
				}
		}
  };
</script>
