<template>
    <el-row :gutter="15">
        <el-col :span="8">
            <h3 style="margin-top: 0px;">Marketler</h3>
            <el-row :gutter="5">
                <el-col :span="12">
                    <el-input v-model="search.name" placeholder="Market ara"></el-input>
                </el-col>
                <el-col :span="12">
                    <el-input v-model="search.id" placeholder="Market ID ara"></el-input>
                </el-col>
                <el-col :span="12" style="margin-top: 10px;">
                    <el-button style="width: 100%;" @click="addAllItems()" icon="el-icon-check" type="success"><b>Tümünü Ekle</b></el-button>
                </el-col>
                <el-col :span="12" style="margin-top: 10px;">
                    <el-popconfirm
                            confirm-button-text='Sil'
                            cancel-button-text='Kapat'
                            icon="el-icon-warning"
                            @onConfirm="removeAllItems()"
                            icon-color="red"
                            title="Grubu temizlemek istediğinize emin misiniz?"
                            >
                    <el-button style="width: 100%;" slot="reference" icon="el-icon-minus" type="danger"><b>Tümünü Çıkar</b></el-button>
            </el-popconfirm>
                </el-col>
            </el-row>
            <div class="list-items scroll" v-loading="filterLoading">

                <div  :key="key" class="item" v-for="(item,key) in filteredMarkets">
                    <span>#{{ item.id }} <br></span>
                    <b v-if="item.translations['tr']"> {{ item.translations['tr'].value }}</b>
                    <b v-else-if="item.translations['en']">{{ item.translations['en'].value }}</b>
                    <el-button v-if="!_.find(markets, ['market_id', item.id])" @click="addGroup(item.id)" size="small" style="width: 120px;margin-top: 5px;" icon="el-icon-check" type="primary"><b>Gruba Ekle</b></el-button>
                    <el-button v-if="_.find(markets, ['market_id', item.id])"  size="small" style="width: 120px;margin-top: 5px;" icon="el-icon-check" type="success"><b>Eklendi</b></el-button>
                </div>
            </div>
        </el-col>
        <el-col :span="16">
            <h3 style="margin-top: 0px;">Gruptaki Marketler</h3>
            <div class="list-items box scroll" style="height:690px; padding-top: 10px;" v-loading="marketLoading">
                <div :key="key" class="item" v-for="(item,key) in _.orderBy(markets, ['row'], ['asc'])">
                    <el-input @blur="updateGroup" v-model="item.row"></el-input>
                    <b>#{{ item.market_id }}</b>
                    <el-button round @click="removeFromGroup(item)" size="small" style="width:150px;margin-top: 5px;" icon="el-icon-minus" type="danger"><b>Çıkar</b></el-button>
                </div>
            </div>
        </el-col>
    </el-row>
</template>

<script>
    export default{
        props:['sport_id','id', 'type'],
        data(){
            return{
                paginationFilter:{
                    sport_id: null,
                    pageTotal: 0,
                    size: 500,
                    sort: null,
                    page: 1,
                },
                search:{
                    name:null,
                    id:null
                },
                filterLoading:false,
                marketLoading:false,
                globalMarkets:null,
                markets:[],
            }
        },
        created(){
            this.fetch();
            this.paginationFilter.sport_id = this.sport_id;
            this.fetchSports();
            this.fetchMarkets();
        },
        computed:{
            filteredMarkets(){
                  if(this.search.name){
                    return this.globalMarkets.filter(item => item.translations['tr'].value.toLowerCase().includes(this.search.name.toLowerCase()))
                  }else if(this.search.id){
                    return this.globalMarkets.filter(item => _.toString(item.id).includes(_.toString(this.search.id)))
                  }else{
                    return this.globalMarkets
                  }
            }
        },
        watch:{
            'search.name'(){
                this.search.id = null
            },
            'search.id'(){
                this.search.name = null
            },
            'paginationFilter.sport_id'(){
                this.fetchMarkets();
            }
        },
        methods:{
            removeFromGroup(id){
                const index = this.markets.indexOf(id);
                if (index > -1) { 
                    this.markets.splice(index, 1); 
                }
                this.$store.dispatch('alert/message', { type: 'success', title: 'Market Çıkarıldı!'});
                this.updateGroup()
            },
            removeAllItems(){
                this.markets = []
                this.updateGroup()
            },
            addAllItems(){
                _.forEach(this.filteredMarkets,((item,itemKey) => {
                    if(!_.find(this.markets, ['market_id', item.id])){
                        this.markets.push({
                            id: item.id,
                            market_id: item.id,
                            row: 99999
                        })
                    }
                }));
                this.$store.dispatch('alert/message', { type: 'success', title: 'Marketler eklendi!'});
                this.updateGroup()
            },
            addGroup(id, alert = true){
                if(!_.find(this.markets, ['market_id', id])){
                    this.markets.push({
                        id: id,
                        market_id: id,
                        row: 99999
                    })
                    if(alert){
                        this.$store.dispatch('alert/message', { type: 'success', title: 'Market eklendi!'});
                    }
                    this.updateGroup()
                }
            },
            fetchSports(){
                this.loading = true;
                axios.get('/sports', { params: { ...this.paginationFilter } })
                .then((response) => {
                    this.sports = response.data.data;
                    this.paginationFilter.pageTotal  	= response.data.meta.total;
                })
                .catch((err) => {
                })
                .finally(()=>{
                this.loading = false;
                });
            },
            handleSizeChange(value){
                this.paginationFilter.size = value;
                this.fetch();
            },
            fetchMarkets(){
                this.filterLoading = true;
                axios.get('/markets', { params: { ...this.paginationFilter, type : this.type  } })
                .then((response) => {
                    this.globalMarkets = response.data.data;
                    this.paginationFilter.pageTotal  	= response.data.meta.total;
                })
                .catch((err) => {
                })
                .finally(()=>{
                this.filterLoading = false;
                });
            },
            updateGroup(){
                this.loading = true;
                axios.post('/markets/groups/' + this.id + '/list', { markets: this.markets})
                .then((response) => {
                    this.$store.dispatch('alert/message', { type: 'success', title: 'Grup Güncellendi!'});
                    this.fetch()
                })
                .catch((err) => {
                })
                .finally(()=>{
                    this.loading = false;
                });
            },
            fetch(){
                this.marketLoading = true;
                axios.get('/markets/groups/' + this.id + '/list')
                .then((response) => {
                    this.markets = response.data.data 
                })
                .catch((err) => {
                })
                .finally(()=>{
                    this.marketLoading = false;
                });
            },
        }
    }
</script>