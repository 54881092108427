import _ from "lodash";
import router from '@/router'

const state = {
  sports: {},
  categories: {},
  tournaments: {},
}
const getters = {
  sports(state) {
    return state.sports
  },
  categories(state) {
    return state.categories
  },
  tournaments(state) {
    return state.tournaments
  },
}
const mutations = {
  SET_MARKETS(state, payLoad){
    state.markets = payLoad;
  },
  SET_ODDS(state, payLoad){
    state.odds = payLoad;
  },
  ADD_SPORTS(state, payLoad){
    state.sports[payLoad.key] = payLoad.val;
  },
  SET_SPORTS(state, payLoad){
    state.sports = payLoad;
  },
  ADD_CATEGORIES(state, payLoad){
    state.categories[payLoad.key] = payLoad.val;
  },
  SET_CATEGORIES(state, payLoad){
    state.categories = payLoad;
  },
  ADD_TOURNAMENTS(state, payLoad){
    state.tournaments[payLoad.key] = payLoad.val;
  },
  SET_TOURNAMENTS(state, payLoad){
    state.tournaments = payLoad;
  },
  SET_OUTRIGHTS(state, payLoad){
    state.outrights = payLoad;
  },
  SET_COMPETITORS(state, payLoad){
    state.competitors = payLoad;
  },
  ADD_COMPETITORS(state, payLoad){
    state.competitors[payLoad.key] = payLoad.val;
  },
}
const actions = {
  // fetchMarkets({ commit, dispatch, state}, payLoad){
  //   axios.get('/markets').then((response) => {
  //     commit('SET_MARKETS', response.data)
  //   })
  //   .catch((c) => {
  //     commit('SET_MARKETS', null);
  //   })
  //   .finally((f) => { 
  //   })
  // },
  // fetchOdds({ commit, dispatch, state}, payLoad){
  //   axios.get('/odds').then((response) => {
  //     commit('SET_ODDS', response.data)
  //   })
  //   .catch((c) => {
  //     commit('SET_ODDS', null);
  //   })
  //   .finally((f) => { 
  //   })
  // },
  fetchOutrights({ commit, dispatch, state}, payLoad){
    axios.get('/outrights').then((response) => {
      commit('SET_OUTRIGHTS', response.data.data)
    })
    .catch((c) => {
      commit('SET_OUTRIGHTS', null);
    })
    .finally((f) => { 
    })
  },
  fetchCompetitors({ commit, dispatch, state}, payLoad){
    axios.get('/competitors').then((response) => {
      commit('SET_COMPETITORS', response.data.data)
    })
    .catch((c) => {
      commit('SET_COMPETITORS', null);
    })
    .finally((f) => { 
    })
  },
  fetchSports({ commit, dispatch, state}, payLoad){
    axios.get('/sports').then((response) => {
      commit('SET_SPORTS', response.data.data)
    })
    .catch((c) => {
      commit('SET_SPORTS', null);
    })
    .finally((f) => { 
    })
  },
  fetchTournaments({ commit, dispatch, state}, payLoad){
    axios.get('/tournaments').then((response) => {
      commit('SET_TOURNAMENTS', response.data.data)
    })
    .catch((c) => {
      commit('SET_TOURNAMENTS', null);
    })
    .finally((f) => { 
    })
  },
  fetchCategories({ commit, dispatch, state}, payLoad){
    axios.get('/categories').then((response) => {
      commit('SET_CATEGORIES', response.data.data)
    })
    .catch((c) => {
      commit('SET_CATEGORIES', null);
    })
    .finally((f) => { 
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}